var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide-down" } }, [
    _vm.showModal
      ? _c("div", { staticClass: "quotation-body__container expanded-modal" }, [
          _c(
            "div",
            { staticClass: "expanded-modal-content" },
            [
              _vm.type === "multiseller"
                ? [
                    _c(
                      "div",
                      { staticClass: "modal-body" },
                      [
                        _vm._l(
                          _vm.quotationDetails.sellers[0].sellers,
                          function (seller) {
                            return _c(
                              "div",
                              { staticClass: "productList-container" },
                              [
                                _vm._l(seller.shipments, function (shipment) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "header-title__store" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "store-name__title" },
                                          [_vm._v(_vm._s(shipment.sellerName))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "productList-table-header",
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "text-center start" },
                                          [_vm._v("Produtos")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-center d-none d-lg-flex",
                                          },
                                          [_vm._v("Quantidade")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-center d-lg-none",
                                          },
                                          [_vm._v("Qtd")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          { staticClass: "text-center" },
                                          [_vm._v("Preço")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-center d-none d-lg-flex",
                                          },
                                          [_vm._v("Data da Entrega")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-center minWidth",
                                          },
                                          [_vm._v(" Total ")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          gap: "12px",
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                      },
                                      _vm._l(seller.items, function (product) {
                                        return _c(
                                          "div",
                                          { staticClass: "productList-table" },
                                          [
                                            product.images
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "productDetail",
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "img-fluid",
                                                      attrs: {
                                                        src: product.images
                                                          .small[0].url,
                                                        alt: product.images
                                                          .small[0].alt,
                                                        title:
                                                          product.images
                                                            .small[0].title,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "product-name__table",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            product.productName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "text-center" },
                                              [_vm._v(_vm._s(product.quantity))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    product.price.sales
                                                      .formatted
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center d-none d-lg-flex",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    shipment.deliveryDateFormatted
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-center minWidth",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    product.priceTotal.price
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "priceResume" }, [
                                  _c("p", [_vm._v("Subtotal")]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        seller.totals.subTotalPriceFormatted
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "priceResume" }, [
                                  _c("p", [_vm._v("Frete")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "shipping-total__freight" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          seller.totals
                                            .shippingTotalPriceValue === 0
                                            ? "Grátis"
                                            : seller.totals
                                                .shippingTotalPriceFormatted
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "priceResume total" }, [
                          _c("p", { staticClass: "priceTotals" }, [
                            _vm._v("Total"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "shipping-total__price" }, [
                            _vm._v(
                              _vm._s(
                                _vm.quotationDetails.total
                                  ? _vm.quotationDetails.total.formatted
                                  : _vm.quotationDetails.sellers[0].total
                                      .formatted
                              )
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]
                : [
                    _c("div", { staticClass: "modal-body" }, [
                      _c(
                        "div",
                        { staticClass: "productList-container" },
                        [
                          _vm._l(
                            _vm.quotationDetails.shipments,
                            function (shipment) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "header-title__store" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "store-name__title" },
                                      [_vm._v(_vm._s(shipment.sellerName))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "productList-table-header" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "text-center start" },
                                      [_vm._v("Produtos")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-center d-none d-lg-flex",
                                      },
                                      [_vm._v("Quantidade")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "text-center d-lg-none" },
                                      [_vm._v("Qtd")]
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-center" }, [
                                      _vm._v("Preço"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-center d-none d-lg-flex",
                                      },
                                      [_vm._v("Data da Entrega")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "text-center minWidth" },
                                      [_vm._v(" Total ")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      gap: "12px",
                                      display: "flex",
                                      "flex-direction": "column",
                                    },
                                  },
                                  _vm._l(
                                    _vm.quotationDetails.items.items,
                                    function (product) {
                                      return _c(
                                        "div",
                                        { staticClass: "productList-table" },
                                        [
                                          product.images
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "productDetail",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "img-fluid",
                                                    attrs: {
                                                      src: product.images
                                                        .small[0].url,
                                                      alt: product.images
                                                        .small[0].alt,
                                                      title:
                                                        product.images.small[0]
                                                          .title,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "product-name__table",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.productName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(product.quantity))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  product.price.sales.formatted
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center d-none d-lg-flex",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  shipment.deliveryDateFormatted
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-center minWidth",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(product.priceTotal.price)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            }
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "priceResume" }, [
                            _c("p", [_vm._v("Subtotal")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.quotationDetails.totals.subTotal.formatted
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "priceResume" }, [
                            _c("p", [_vm._v("Frete")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "shipping-total__freight" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.quotationDetails.totals
                                      .totalShippingCost.formatted
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "priceResume total" }, [
                        _c("p", { staticClass: "priceTotals" }, [
                          _vm._v("Total"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "shipping-total__price" }, [
                          _vm._v(
                            _vm._s(
                              _vm.quotationDetails.totals.grandTotal.formatted
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.quotationDetails.invalidProducts.length >= 1
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "productList-container" },
                              [
                                [
                                  _c(
                                    "div",
                                    { staticClass: "warning-advice__image" },
                                    [_c("img", { attrs: { src: "", alt: "" } })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-style": "italic",
                                        "font-size": "14px",
                                        color: "#6A0000",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Os produtos abaixo não estão\n                                        disponíveis neste vendedor\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "invalidProducts__container",
                                    },
                                    _vm._l(
                                      _vm.quotationDetails.invalidProducts,
                                      function (product) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass:
                                              "productList-table invalidProducts",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "productDetail" },
                                              [
                                                _c("div", {
                                                  staticClass: "img-fluid",
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "product-name__table invalid_product-name",
                                                  },
                                                  [_vm._v(_vm._s(product.name))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }