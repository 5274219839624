import routes from '../../api/routes';
import loginApi from '../../api/login';

const state = () => ({
    logged: false,
    loginErr: null,
    email: ''
});

const getters = {
    logged:(state) => {
        return state.logged;
    },
    loginErr:(state) => {
        return state.loginErr;
    },
    email:(state) => {
        return state.email;
    },
};

const actions = {
    login ({ commit, dispatch, rootState }, data) {
        $('.cta-button').addClass('is__loading')
        let req = loginApi.submitLogintReq(
            routes.account.logincheckout,
            data,
            rootState
        );
        $.ajax({
            url: req.url,
            type: 'post',
            dataType: 'json',
            data: req.options.body,
            success: function (data) {
                if(data.success) {
                    $('.cta-button').removeClass('is__loading')
                    location.href = data.redirectUrl
                } else {
                    commit('setLoginErr', data.error[0])
                    $('.cta-button').removeClass('is__loading')
                }
            },
            error: function (data) {

            }
        })
    },
};

const mutations = {
    setLogged(state, data){
        state.logged = data;
    },
    setLoginErr(state, data){
        state.loginErr = data;
    },
    setEmail(state, data){
        state.email = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
