import { render, staticRenderFns } from "./CheckoutReview.vue?vue&type=template&id=6ff3ab0c"
import script from "./CheckoutReview.vue?vue&type=script&lang=js"
export * from "./CheckoutReview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/caiomasseu/Documents/Backlgrs/backlgrs.sfcc.suzano/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ff3ab0c')) {
      api.createRecord('6ff3ab0c', component.options)
    } else {
      api.reload('6ff3ab0c', component.options)
    }
    module.hot.accept("./CheckoutReview.vue?vue&type=template&id=6ff3ab0c", function () {
      api.rerender('6ff3ab0c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "cartridges/app_suzano_custom/cartridge/client/default/js/vueComponents/components/CheckoutReview.vue"
export default component.exports