<template>
    <modal
        modalId="modalShareInfo"
        size="md"
        :showCloseButton="true"
        :isStatic="true"
        :startOpen="isShareQuotation ? true : false"
        @hande:close="handleClose"
    >
        <template v-slot:title>
            Bem-vindo ao suprijá!
        </template>
        <template v-slot:body>
            <p>Aqui oferecemos mais de 3 cotações com entrega rápida a partir de lojas da sua regiao.</p>
            <p v-if="addressModal.city && addressModal.postalCode">
                A cotação que você recebeu contém produtos de <b> {{addressModal.city}} | {{addressModal.postalCode}}</b>.
            </p>
        </template>
        <template v-slot:footer>
            <button style="width: 100%;" @click="handleClose" class="btn button button-secondary">Visualizar minha cotação</button>
        </template>
    </modal>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Modal from '../Modal.vue';

    export default {
        components: {
            Modal,
        },

        computed: {
            ...mapGetters('checkout', {
                isShareQuotation: 'isShareQuotation',
                addressModal: 'addressModal',
            }),
        },

        methods: {
            handleClose() {
                this.$store.commit('checkout/setIsShareQuotation', false)
                $('#modalShareInfo').modal('hide');
            }
        }

    }
</script>