var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-message" }, [
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "modalMessage",
          "data-bs-backdrop": "static",
          "data-bs-keyboard": "false",
          tabindex: "-1",
          "aria-hidden": "true",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("handle:click")
          },
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "h5",
                {
                  staticClass: "modal-title title3 fs-5",
                  attrs: { id: "exampleModalLabel" },
                },
                [_vm._v(" " + _vm._s(_vm.title))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "close-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "close btn-close",
                    attrs: {
                      type: "button",
                      "data-bs-dismiss": "modal",
                      "aria-label": "Close",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("handle:click")
                      },
                    },
                  },
                  [_c("img", { attrs: { src: _vm.closeurl, alt: "Fechar" } })]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.messageHtml
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("p", [
                    _vm._v(
                      "Lamentamos que seu pedido não tenha sido realizado. Pedimos que verifique as seguintes informações e tente novamente."
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                ])
              : _c("div", { staticClass: "modal-body" }, [
                  _vm._v(_vm._s(_vm.message)),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-try-again",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handle:click")
                    },
                  },
                },
                [_vm._v("Tentar Novamente")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Dados de Entrega")]),
      _vm._v(" "),
      _c("li", [_vm._v("Endereço de Cobrança")]),
      _vm._v(" "),
      _c("li", [_vm._v("CPF/CNPJ")]),
      _vm._v(" "),
      _c("li", [_vm._v("Dados de Pagamento")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Se você tiver mais problemas, fale conosco no Whatsapp "),
      _c("a", { attrs: { href: "tel:+5511995690583" } }, [
        _vm._v("(11 99569-0583)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }