var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: {
      modalId: "modalShareInfo",
      size: "md",
      showCloseButton: true,
      isStatic: true,
      startOpen: _vm.isShareQuotation ? true : false,
    },
    on: { "hande:close": _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v("\n        Bem-vindo ao suprijá!\n    ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [
              _vm._v(
                "Aqui oferecemos mais de 3 cotações com entrega rápida a partir de lojas da sua regiao."
              ),
            ]),
            _vm._v(" "),
            _vm.addressModal.city && _vm.addressModal.postalCode
              ? _c("p", [
                  _vm._v(
                    "\n            A cotação que você recebeu contém produtos de "
                  ),
                  _c("b", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.addressModal.city) +
                        " | " +
                        _vm._s(_vm.addressModal.postalCode)
                    ),
                  ]),
                  _vm._v(".\n        "),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn button button-secondary",
                staticStyle: { width: "100%" },
                on: { click: _vm.handleClose },
              },
              [_vm._v("Visualizar minha cotação")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }