<template>
    <div class="quotationBody allStoresComponent">
        <div class="seller__detail">
            <template v-if="type==='multiseller'">
                <!-- carousel de sellers opcao multiseller -->
                <div class="quotation-sellers-carousel">
                    <div v-if="quotation" class="seller__detail" v-for="(seller, index) in quotation.sellers">
                        <img v-if="seller.images" loading="lazy" :src="seller.images.url" :alt="seller.images.alt">
                    </div>
                </div>
            </template>
            <template v-else>
                <img v-if="quotation.images" loading="lazy" :src="quotation.images.url" :alt="quotation.images.alt">
                <img v-else loading="lazy" src="teste" alt="teste">
            </template>
        </div>

        <div class="quotationBody__col quotationBody__col__SellerName-mobile">           
            <template v-if="type==='seller'">
                <!-- seller name-->
                <h5 class="d-none d-lg-flex">{{ quotation.name }}</h5>
 
                <!-- flag -->
                <div v-if="quotation.flag" class="quotation__flag" :class="quotation.flag.id">
                    <img loading="lazy" :src="quotation.flag.image.url" :alt="quotation.flag.image.alt">
                    <span>{{ quotation.flag.name }} </span>
                </div>
            </template>

            <template v-else>
                <!-- seller name-->
                <h5 class="d-none d-lg-flex">Múlti Lojas</h5>
 
                <!-- flag -->
                <div v-if="quotationModel.multisellers.flag" class="quotation__flag" :class="quotationModel.multisellers.flag.id">
                    <img loading="lazy" :src="quotationModel.multisellers.flag.image.url" :alt="quotationModel.multisellers.flag.image.alt">
                    <span> {{quotationModel.multisellers.flag.name }} </span>
                </div>
            </template>
        </div>

        <div class="quotationBody__col">
            <div class="d-lg-none"  style="padding-bottom: 8px;">
                <h5 v-if="type==='seller'">{{ quotation.name }}</h5>
                <h5 v-else>Múlti Lojas</h5>
            </div>
            <p>Preço total</p>
            <span v-if="type==='seller'">{{ quotation.totals.grandTotal.formatted }}</span>
            <span v-else>{{ quotation.total.formatted }}</span>
        </div>

        <div class="quotationBody__col">
            <p>Data de entrega</p>
            <span v-if="type==='seller'">{{ quotation.shippingDate }}</span>
            <span v-else>Múltiplas Entregas</span>
        </div>

        <div class="quotationBody__col d-lg-none">
        </div>

        <div class="quotationBody__col d-none d-lg-flex">
            <template v-if="type==='seller'">
                <p v-if="quotation.items.totalQuantity === quotationModel.totalBasketItems" class="allProducts__shippment">Entrega todos os produtos</p>
                <p v-else class="allProducts__shippment">Entrega {{ quotation.items.totalQuantity }} de {{quotationModel.totalBasketItems }} produtos </p>
            </template>
            <template v-else>
                <p class="allProducts__shippment">Entrega todos os produtos</p>
            </template>
            <span class="seeDetails" type="button" @click.stop="$emit('handle:quotation')">Ver detalhes</span>
        </div>

        <div class="quotationBody__col d-lg-none">
            <template v-if="type==='seller'">
                <p v-if="quotation.items.totalQuantity === quotationModel.totalBasketItems" class="allProducts__shippment">Entrega todos os produtos</p>
                <p v-else class="allProducts__shippment">Entrega {{ quotation.items.totalQuantity }} de {{quotationModel.totalBasketItems }} produtos </p>
            </template>
            <template v-else>
                <p class="allProducts__shippment">Entrega todos os produtos</p>
            </template>
        </div>
        <div class="quotationBody__col d-lg-none">
            <span class="seeDetails" type="button" @click.stop="$emit('handle:quotation')">Ver detalhes</span>
        </div>

        <div class="cta__container">
            <button class="btn quotation-btn">Escolher</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        quotation: {
            default: {},
            type: Object,
        },
        quotationModel: {
            default: {},
            type: Object,
        },
        type: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
        }
    },
    methods: {}
}
</script>