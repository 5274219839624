<template>
  <div>
    <div class="pdp-carousel d-none d-lg-block">
      <slick class="thumbnailCarousel" ref="thumbnail" :options="thumbnailOptions">
        <img  class="img-pdp-thumbnailCarousel" v-for="(image, index) in images.images.small" :key="image.index" :src="image.absURL" :alt="image.alt"
          loading="lazy" />
      </slick>
      <slick class="mainCarousel pdpSlider" ref="main" :options="mainOptions">
        <img v-for="(image, index) in images.images.large" :key="image.index" :src="image.absURL" :alt="image.alt"
          loading="lazy" />
      </slick>
    </div>

    <div class="pdp-carousel pdp-mobile-carousel d-lg-none">
      <div class="box">
        <slick class="mainMobileCarousel pdpSlider" ref="main" :options="mainMobileOptions">
          <img v-for="(image, index) in images.images.large" :key="image.index" :src="image.absURL" :alt="image.alt"
            loading="lazy" />
        </slick>
      </div>
      <slick class="thumbnailMobileCarousel" ref="thumbnail" :options="thumbnailMobileOptions">
        <img v-for="(image, index) in images.images.small" :key="image.index" :src="image.absURL" :alt="image.alt"
          loading="lazy" />
      </slick>
    </div>
  </div>

</template>

<script>
import Slick from 'vue-slick';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    Slick
  },
  data() {
    return {
      thumbnailOptions: {
        slidesToShow: 6,
        asNavFor: '.mainCarousel',
        vertical: true,
        focusOnSelect: true,
        autoplay: false,
        centerMode: false
      },
      mainOptions: {
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.thumbnailCarousel',
        autoplay: false,
        centerMode: true,
        centerPadding: '0',
        lazyLoad: 'progressive',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              dots: false,
              mobileFirst: true,
              vertical: false,
              verticalSwiping: false
            }
          }
        ]
      },

      thumbnailMobileOptions: {
        slidesToShow: 6,
        asNavFor: '.mainMobileCarousel',
        autoplay: false,
      },
      mainMobileOptions: {
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.thumbnailMobileCarousel',
        autoplay: false,
        centerPadding: '0',
        lazyLoad: 'progressive'
      }
    };
  }
};
</script>

<style>
.pdp-carousel {
  display: flex;
  gap: 10px;
  height: 100%;
}

.pdp-mobile-carousel{
  flex-direction: column;
  .box{
    overflow: hidden;
    display: flex;
    align-items: center
  }
}

.mainCarousel{
  height: 100%;
  .slick-slide {
    display: flex;
    margin-right: 3%;
    justify-content: center; 
  }
  .slick-slide div {
    display: inline-grid;
    align-items: center;
  }
}
@media (max-width: 992px) {
    .mainCarousel.pdpSlider {
      display: flex;
    }
    .slick-slide {
    display: flex !important;
    justify-content: center;
    margin-right: 0%;
  }
  }

.mainMobileCarousel {
  width: 360px;
  height: 100%;
  .slick-slide div {
    display: inline-grid;
    align-items: center;
  }
}

.pdpSlider.mainCarousel.slick-initialized.slick-pdpSlider.slick-vertical,
.pdpSlider.mainMobileCarousel.slick-initialized.slick-pdpSlider.slick-vertical {
  text-align: -webkit-center;
  visibility: visible;
  opacity: 1;
}

.pdpSlider.mainCarousel.slick-initialized,
.pdpSlider.mainMobileCarousel.slick-initialized {
  .mainImage {
    display: block !important;
  }
}

.thumbnailCarousel {
  width: 80px;
  float: left;

  .slick-slide div {
    width: 100%;
    height: 80px;
    display: inline-grid;
    border-radius: 5px;
    border: .5px solid #d0d0d0;
    background: #fff;
    flex-shrink: 0;
    padding: 10px 12px;
    margin-bottom: 10px;
    align-items: center;
    overflow: hidden;
  }
}

.thumbnailMobileCarousel {
  width: 100%;

  .slick-track{
    width: 100% !important;
  }
  .slick-slide {
    width: 93px !important;
    margin-right: 10px;
  }
  .slick-slide div {
    width: 93px !important;
    height: 93px !important;
    display: inline-grid;
    border-radius: 5px;
    border: .5px solid #d0d0d0;
    background: #fff;
    flex-shrink: 0;
    padding: 10px 12px;
    align-items: center;
    overflow: hidden;

    img{
      width: 73px !important;
    }
  }
}
</style>
