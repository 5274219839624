<template>
    <div class="p-3 shippingBox">
        <div class="shippingBox__content">
            <h2>DADOS DE ENTREGA</h2>

            <div class="row cpfCnpjMargin">
                <div :class="isMobile ? (changeFlag ? 'col-9' : 'col-12') : (changeFlag ? 'col-10' : 'col-12')">
                    <div class="form-group"
                        :class="[forms.billingForm.contactInfoFields.cpfCnpj.mandatory === true ? 'required': '',
                        forms.billingForm.contactInfoFields.cpfCnpj.htmlName]">
                        <input
                            type="text"
                            id="cpfCnpj"
                            :required="forms.billingForm.contactInfoFields.cpfCnpj.mandatory === true ? true: false"
                            inputmode="numeric"
                            placeholder="Digite seu CPF ou CNPJ"
                            class="form-control cpfCnpj checkoutInputs"
                            :class="{'error': formErrorsContact.dwfrm_billing_contactInfoFields_cpfCnpj}"
                            v-model="contactInfoModel[forms.billingForm.contactInfoFields.cpfCnpj.htmlName]"
                            :maxlength="forms.billingForm.contactInfoFields.cpfCnpj.maxLength"
                            :minLength="forms.billingForm.contactInfoFields.cpfCnpj.minLength"
                            v-mask=cpfCnpjMask
                            @input="validateCpfCnpj"
                        >
                        <div v-show="formErrorsContact.dwfrm_billing_contactInfoFields_cpfCnpj" class="error-message" id="cpfInvalidMessage">
                            {{ formErrorsContact.dwfrm_billing_contactInfoFields_cpfCnpj}}
                        </div>
                        <span class="loader" v-show="isLoading"></span>
                        <span class="icon-doc" v-show="isDocChecked"> <i class="icon-check"></i></span>
                    </div>
                </div>
                <div :class="isMobile ? 'col-3' : 'col-2'" v-show="changeFlag">
                    <button
                        type="button"
                        class="btn-change"
                        @click="changeDoc">
                        <i class="icon-change"></i> <span>Alterar</span>
                    </button>
                </div>
            </div>

            <!-- innerstep 2 -->
            <div v-if="innerStep===2 || logged" style="display: flex; flex-direction: column;">
                <!-- form de dados para submitshipping -->
                <shipping-form :errors="formErrors" :user="user" @updateErrors="updateFormErr" :type="documentType" :isMobile="isMobile"></shipping-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import routes from '../../api/routes';
    import ShippingForm from './shipping/ShippingForm.vue';
    import HandleStep from './HandleStep.vue';

    export default {
        props: ['activeStep'],
        components: {
            ShippingForm,
            HandleStep,
        },
        data() {
            return {
                documentType: 'CPF',
                state: 'inicial',
                isLoading:false,
                isDocChecked:false,
                changeFlag: false,
                innerStep: 1,
                screenWidth: window.innerWidth,
                url: routes.account.logincheckout,
                formErrors: {},
                formErrorsContact: {},
                contactInfoModel: {
                    'dwfrm_billing_contactInfoFields_cpfCnpj': '',
                    'dwfrm_billing_contactInfoFields_phone': '',
                    'dwfrm_billing_contactInfoFields_documentType': '',
                },
                user: {
                    email: ''
                }
            }
        },
        created() {
            this.$root.$refs.ShippingStep = this;
        },
        mounted(){
            if(this.logged) {
                this.user.email = this.customer.profile.email
                this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName] = this.customer.profile.document;
            }
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
        computed: {
            ...mapGetters('checkout', {
                forms: "forms",
                csrf: 'csrf',
                addressModel: 'addressModel',
                customer: 'customer',
            }),
            ...mapGetters('account', {
                logged: 'logged',
                loginErr: 'loginErr',
            }),
            cpfCnpjMask() {
                this.documentType = this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName].length > 14 ? 'CNPJ' : 'CPF';
                this.contactInfoModel['dwfrm_billing_contactInfoFields_documentType'] = this.documentType;
                return this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName].length > 14 ? '##.###.###/####-##' : '###.###.###-##';
            },
            isMobile() {
                return this.screenWidth < 992;
            },
        },
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth;
                // console.log("🚀 ~ file: ShippingForm.vue ~ line 139 ~ this.screenWidth", this.screenWidth)
            },
            scrollToTop() {
                window.scrollTo(0,0);
            },
            validateStep(step){
                if (step <= this.activeStep) return true;
                else return false;
            },
            isValidEmail(email) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(email);
            },
            goToStep(step){
                this.innerStep=step;
            },
            async submitForm(model){
                model['dwfrm_customer_email'] = this.user.email;
                // this.contactInfoModel[this.forms.billingForm.contactInfoFields.phone.htmlName] = this.customer.profile.phone;


                var result = await this.$store.dispatch('checkout/confirmShipping', {...model, addressId: model.dwfrm_shipping_shippingAddress_addressFields_postalCode+' '+model.dwfrm_shipping_shippingAddress_addressFields_address1+' '+model.dwfrm_shipping_shippingAddress_addressFields_at__address__number, csrf_token: this.csrf.token, });

                // console.log("🚀 ~ submitForm ~ result:", result);

                if(!result.error) {
                    //datalayer trigger add_shipping_info
                    $('body').trigger('dataLayerPush:event', result.add_shipping_info);

                    this.$root.$refs.Checkout.nextStep()
                }
            },
            handleClick(){
                if(this.validateForm()){
                    this.submitForm(this.addressModel)
                    this.$root.$refs.CheckoutReview.disabled = true;
                    this.$root.$refs.CheckoutSummary.disabled = true;
                } else {
                    $(".continue-btn").removeClass("is__loading");
                }
            },
            validateForm() {
                this.formErrors = {};

                if (this.user.email === '') {
                    this.$set(this.formErrors, 'email', 'Campo obrigatório.');
                } else if (!this.isValidEmail(this.user.email)) {
                    this.$set(this.formErrors, 'email', 'E-mail inválido.');
                }

                Object.keys(this.addressModel).forEach(key => {
                    if (this.addressModel[key] === '' && key!=='dwfrm_shipping_shippingAddress_addressFields_address2') {
                        this.$set(this.formErrors, key, 'Campo obrigatório.');
                    }
                });

                this.validateContactInfoModelForm()

                return Object.values(this.formErrors).every(error => !error);
            },
            updateFormErr({ key, value }) {
                this.$set(this.formErrors, key, value);
            },
            validateCpfCnpj() {
                this.isDocChecked = false;
                this.formErrorsContact = {};
                if ( this.contactInfoModel.dwfrm_billing_contactInfoFields_cpfCnpj.length  === 14 || this.contactInfoModel.dwfrm_billing_contactInfoFields_cpfCnpj.length  === 18) {
                    this.isLoading = true;
                    this.validateDocForm();
                }
            },
            isValidCPF(cpf) {
                if (cpf.length !== 11) return false;

                // Verificar se todos os dígitos são iguais
                if (/^(\d)\1{10}$/.test(cpf)) return false;

                // Calcular dígitos verificadores
                let sum = 0;
                for (let i = 0; i < 9; i++) {
                    sum += parseInt(cpf.charAt(i)) * (10 - i);
                }
                let checkDigit1 = 11 - (sum % 11);
                if (checkDigit1 > 9) checkDigit1 = 0;

                sum = 0;
                for (let i = 0; i < 10; i++) {
                    sum += parseInt(cpf.charAt(i)) * (11 - i);
                }
                let checkDigit2 = 11 - (sum % 11);
                if (checkDigit2 > 9) checkDigit2 = 0;

                // Verificar dígitos verificadores
                if (parseInt(cpf.charAt(9)) !== checkDigit1 || parseInt(cpf.charAt(10)) !== checkDigit2) return false;

                return true;
            },
            isValidCNPJ(cnpj) {
                if (cnpj == '') return false;

                if (cnpj.length != 14) return false;

                // Valida DVs
                var tamanho = cnpj.length - 2
                var numeros = cnpj.substring(0, tamanho);
                var digitos = cnpj.substring(tamanho);
                var soma = 0;
                var pos = tamanho - 7;
                for (var i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2)
                        pos = 9;
                }
                var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(0))
                    return false;

                tamanho = tamanho + 1;
                numeros = cnpj.substring(0, tamanho);
                soma = 0;
                pos = tamanho - 7;
                for (i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2)
                        pos = 9;
                }
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado != digitos.charAt(1))
                    return false;

                return true;
            },

            validDocument(document) {
                document = document.replace(/[^\d]/g, '');
                this.isLoading = false;
                if (document.length !== 11 && document.length !== 14) return false;

                if (document.length === 11) {
                    return this.isValidCPF(document);
                } else if (document.length === 14) {
                    return this.isValidCNPJ(document);
                }

                return false;
            },
            validateDocForm() {
                this.formErrorsContact = {};

                this.isDocChecked = false;
                this.$root.$refs.CheckoutReview.disabled = true;
                this.$root.$refs.CheckoutSummary.disabled = true;

                Object.keys(this.contactInfoModel).forEach(key => {
                    if (key === 'dwfrm_billing_contactInfoFields_cpfCnpj' && this.contactInfoModel[key] !== '' && !this.validDocument(this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName])) {
                        this.$set(this.formErrorsContact, key, 'Número do documento inválido.');
                    }

                    // else if(key === 'dwfrm_billing_contactInfoFields_phone' && this.contactInfoModel[key] === '' && this.addressModel['dwfrm_shipping_shippingAddress_addressFields_phone'] === '') {
                    //     this.$set(this.formErrors, 'dwfrm_shipping_shippingAddress_addressFields_phone', 'Campo obrigatório.');
                    // } else if (this.contactInfoModel[key] === '') {
                    //     this.$set(this.formErrorsContact, key, 'Campo obrigatório.');
                    // }
                    else{
                        this.isDocChecked = true;
                        this.$root.$refs.CheckoutReview.disabled = false;
                        this.$root.$refs.CheckoutSummary.disabled = false;
                        this.innerStep != 2 ? this.goToStep(2) : null;
                    }
                });

                return Object.values(this.formErrorsContact).every(error => !error);
            },
            validateContactInfoModelForm() {
                this.formErrorsContact = {};

                this.isDocChecked = false;
                this.$root.$refs.CheckoutReview.disabled = true;
                this.$root.$refs.CheckoutSummary.disabled = true;

                Object.keys(this.contactInfoModel).forEach(key => {
                    if (key === 'dwfrm_billing_contactInfoFields_cpfCnpj' && this.contactInfoModel[key] !== '' && !this.validDocument(this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName])) {
                        this.$set(this.formErrorsContact, key, 'Número do documento inválido.');
                    } else if(key === 'dwfrm_billing_contactInfoFields_phone' && this.contactInfoModel[key] === '' && this.addressModel['dwfrm_shipping_shippingAddress_addressFields_phone'] === '') {
                        this.$set(this.formErrors, 'dwfrm_shipping_shippingAddress_addressFields_phone', 'Campo obrigatório.');
                    } else if (this.contactInfoModel[key] === '') {
                        this.$set(this.formErrorsContact, key, 'Campo obrigatório.');
                    }
                    else{
                        this.isDocChecked = true;
                        this.$root.$refs.CheckoutReview.disabled = false;
                        this.$root.$refs.CheckoutSummary.disabled = false;
                        this.innerStep != 2 ? this.goToStep(2) : null;
                    }
                });

                return Object.values(this.formErrorsContact).every(error => !error);
            },


            changeDoc() {
                this.contactInfoModel[this.forms.billingForm.contactInfoFields.cpfCnpj.htmlName] = '';
                this.isDocChecked = false;
                this.$root.$refs.CheckoutReview.disabled = true;
                this.$root.$refs.CheckoutSummary.disabled = true;
                this.formErrorsContact = {};
            }

        },
        watch: {
            contactInfoModel: {
                handler(newValue) {
                    const hasValue = Object.values(newValue).some(value => value !== '');
                    this.changeFlag = hasValue;
                },
                deep: true
            }
        },
    }
</script>

<style lang="scss" scoped>
.form-group {
    &.required .form-control-label::after {
        content: "";
    }
}
select.gray-card-form {
    padding: 6px 12px;
    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
}
.cpfCnpjMargin{
    margin-right: -5px !important;
    margin-left: -5px !important;

    .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        padding-right: 5px !important;
        padding-left: 5px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .form-group .form-control:focus {
        border-width: 1px !important;
    }

    input{
        border: 1px solid #ddd;
    }

    input.error {
        border-color: #d00;
    }

    input::-webkit-input-placeholder {
        color: #6c757d !important;
    }

    input:-moz-placeholder {
        color: #6c757d !important;
    }

    input::-moz-placeholder {
        color: #6c757d !important;
    }

    input:-ms-input-placeholder {
        color: #6c757d !important;
    }
}
.loader {
    position: absolute;
    top: 12px;
    right: 24px;
    width: 24px;
    height: 24px;
    border: 3px solid #009543;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.icon-doc{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color:#009543;
    display: inline-block;
    box-sizing: border-box;
}
.btn-change{
    background-color: #fff;
    border: none;
    color: #03267F;
    font-size: 16px;
    width: 88px;
    height: 48px;
    display: flex;
    align-items: center;

    i{
        width: 18px;
        height: 18px;
        margin-right: 6px;
        text-decoration: none;
    }

    span{
        text-decoration: underline;
    }
}


</style>